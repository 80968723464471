import React, { useState } from 'react';

function PhotoGrid({ photos }) {
    const [selectedPhoto, setSelectedPhoto] = useState(null); // State to track selected photo

    const openModal = (photo) => {
        setSelectedPhoto(photo); // Set the clicked photo as selected
    };

    const closeModal = () => {
        setSelectedPhoto(null); // Reset the selected photo
    };

    return (
        <div>
            <div className="photo-grid">
                {photos.map((photo, index) => (
                    <img
                        key={index}
                        src={photo.src}
                        alt={`Art ${index + 1}`}
                        className="photo-item"
                        onClick={() => openModal(photo)}
                    />
                ))}
            </div>

            {/* Modal */}
            {selectedPhoto && (
                <div className="modal" onClick={closeModal}>
                    <div className="modal-content">
                        <img src={selectedPhoto.src} alt="Selected artwork" />
                        <div className="description" dangerouslySetInnerHTML={{ __html: selectedPhoto.description }}></div>
                        </div>
                </div>
            )}
        </div>
    );
}

export default PhotoGrid;
